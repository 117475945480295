<template>
  <footer class="py-7 px-8 lg:px-20">
    <section class="grid grid-cols-1 lg:grid-cols-3 gap-y-8">
      <div class="flex flex-col justify-center items-center">
        <p>Carrera 67A NO 44-41</p>
        <a href="tel:+573002851942">+57 300 285 1942</a>
        <a href="mailto:Contacto@bcgroup.com.co">Contacto@bcgroup.com.co</a>
        <p>Bogotá D.C</p>
        <p>Colombia</p>
      </div>

      <div>
        <div class="h-72">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15896.77098784118!2d-75.4941952!3d5.0724864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9b67acad3b71%3A0x812e7e53cc6e382a!2sBC%20Group!5e0!3m2!1ses-419!2sco!4v1657908713901!5m2!1ses-419!2sco"
            width="600"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="h-full w-full rounded overflow-hidden shadow"
          ></iframe>
        </div>

        <br />
        <p>Atendemos: Lunes a Viernes</p>
        <p>8:00 AM A 12:00 PM /</p>
        <p>2:00 PM A 6:00 PM</p>
        <p>Política de Tratamiento</p>
        <p>Cookies</p>
      </div>

      <div class="space-x-7 text-5xl flex items-center justify-center">
        <a href="https://www.linkedin.com/in/bedoya-consultores-sas-b4320a162/" target="_blank">
          <i class="fa-brands fa-linkedin"></i>
        </a>
        <a href="https://www.instagram.com/bcgroup.1/" target="_blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
      </div>
    </section>
  </footer>

  <section class="border-y-2 py-6">
    <div class="flex justify-center">
      <img class="w-20 md:w-1/12" src="@/assets/bc-logo-blue.png" alt="Logo Bedoya Consultores" />
    </div>
    <br />
    <p>Copyright © 2022,</p>
    <p>BC GROUP</p>
    <p>Todos los derechos reservados</p>
  </section>

  <section class="py-3">
    <div class="flex flex-col mx-auto md:flex-row space-y-3 md:space-y-0 md:space-x-5 place-content-center place-items-center my-5">
      <h1 class="text-lg font-normal">With Love:</h1>
      <a class="flex justify-center" href="https://softgreen.com.co/" target="_blank" rel="noopener noreferrer">
        <img class="h-12" src="@/assets/softgreen.png" alt="Logo Softgreen" />
      </a>
    </div>
  </section>
</template>
