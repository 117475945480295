<template>
  <NavbarPage class="z-10" />

  <section class="z-0">
    <SliderHome />
    <div class="relative z-30">
      <button @click="scrollTo('#home_section_1')" class="absolute bottom-10 md:bottom-20 left-20 md:left-48"><i class="animate-pulse fa-solid fa-angles-down text-white text-3xl"></i></button>
    </div>
  </section>

  <section id="home_section_1" class="text-white bg-black p-5 sm:py-14">
    <div>
      <h1 class="flex justify-start font-bold text-6xl pl-3 sm:pl-10">SERVICIOS</h1>
    </div>

    <article class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-10 text-white gap-y-8">
      <a href="/Servicios" class="z-0 hover:z-10 mt-0 lg:mt-20 h-128 lg:h-192 flex justify-center">
        <button class="h-full border-2 border-dark-blue lg:border-0">
          <div class="bg-[url('@/assets/business-people-analyzing.jpg')] bg-center bg-cover w-72 xl:w-96 h-full">
            <div class="bg-black bg-opacity-70 hover:bg-opacity-20 h-full py-12">
              <div class="h-full relative z-50">
                <h1 class="text-4xl font-black text-left pl-10 mt-6">
                  OUTSOURCING<br />
                  CONTABLE
                </h1>
                <p class="rotate-[270deg] flex items-end absolute z-0 bottom-1/4 tracking-widest">V I E W | M O R E</p>
              </div>
            </div>
          </div>
        </button>
      </a>
      <a href="/Servicios" class="z-0 hover:z-10 mb-0 lg:mb-20 h-128 lg:h-192 flex justify-center">
        <button class="h-full border-2 border-dark-blue lg:border-0">
          <div class="bg-[url('@/assets/business-man-financial-inspector.jpg')] bg-left bg-cover w-72 xl:w-96 h-full">
            <div class="bg-black bg-opacity-70 hover:bg-opacity-20 h-full py-12">
              <div class="h-full relative z-50">
                <h1 class="text-4xl font-black text-left pl-10 mt-6">
                  CONSULTORÍA<br />
                  TRIBUTARIA
                </h1>
                <p class="rotate-[270deg] flex items-end absolute z-0 bottom-1/4 tracking-widest text-white">V I E W | M O R E</p>
              </div>
            </div>
          </div>
        </button>
      </a>
      <a href="/Servicios" class="z-0 hover:z-10 mt-0 lg:mt-20 h-128 lg:h-192 flex justify-center">
        <button class="h-full border-2 border-dark-blue lg:border-0">
          <div class="bg-[url('@/assets/entrepreneurs-discussing.jpg')] bg-center bg-cover w-72 xl:w-96 h-full">
            <div class="bg-black bg-opacity-70 hover:bg-opacity-20 h-full py-12">
              <div class="h-full relative z-50">
                <h1 class="text-4xl font-black text-left pl-10 mt-6">
                  AUDITORÍA/<br />
                  REVISORÍA<br />
                  FISCAL
                </h1>
                <p class="rotate-[270deg] flex items-end absolute z-0 bottom-1/4 tracking-widest text-white">V I E W | M O R E</p>
              </div>
            </div>
          </div>
        </button>
      </a>
      <a href="/Servicios" class="z-0 hover:z-10 mb-0 lg:mb-20 h-128 lg:h-192 flex justify-center">
        <button class="h-full border-2 border-dark-blue lg:border-0">
          <div class="bg-[url('@/assets/business-woman.jpg')] bg-center bg-cover w-72 xl:w-96 h-full">
            <div class="bg-black bg-opacity-70 hover:bg-opacity-20 h-full py-12">
              <div class="h-full relative z-50">
                <h1 class="text-4xl font-black text-left pl-10 mt-6">
                  CONSULTORÍA<br />
                  IFRS-NIIF
                </h1>
                <p class="rotate-[270deg] flex items-end absolute z-0 bottom-1/4 tracking-widest text-white">V I E W | M O R E</p>
              </div>
            </div>
          </div>
        </button>
      </a>
    </article>

    <div class="mt-5">
      <a href="/Contacto">
        <button class="bg-dark-blue text-xl sm:text-4xl font-bold p-4">CONTÁCTANOS <i class="fa-solid fa-arrow-right-long"></i></button>
      </a>
    </div>
  </section>

  <section class="bg-black text-white relative overflow-hidden">
    <p class="absolute text-9xl font-bold top-0 text-gray-800 z-0">ESTRATÉGICOS</p>
    <div class="relative h-32">
      <h1 class="flex justify-start font-bold text-5xl pl-3 sm:pl-32">ALIADOS</h1>
    </div>
  </section>

  <section>
    <SliderHomeLogo />
  </section>
</template>

<script>
import NavbarPage from "@/components/NavbarPage.vue";
import SliderHome from "@/components/SliderHome.vue";
import SliderHomeLogo from "@/components/SliderHomeLogo.vue";

export default {
  name: "HomeView",
  components: {
    NavbarPage,
    SliderHome,
    SliderHomeLogo,
  },
  methods: {
    scrollTo(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>
