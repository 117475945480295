<template>
  <div>
    <div class="relative shadow-2xl bg-white">
      <div class="relative overflow-hidden w-full">
        <!--Slide 1-->
        <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked" />
        <div class="carousel-item absolute opacity-0">
          <article class="bg-[url(@/assets/business-woman-and-lawyers.jpg)] bg-cover bg-center text-white">
            <div class="bg-black bg-opacity-70 grid grid-cols-12 items-start">
              <div class="border-r border-gray-400 h-full">
                <img class="rotate-[270deg] mt-14" src="@/assets/bc-logo-white.png" alt="Logo Bedoya Consultores" />
              </div>

              <div class="col-span-11 py-44 md:py-72 px-5 md:pr-20 md:pl-44">
                <p class="text-3xl sm:text-5xl lg:text-7xl font-black text-right">COMPROMETIDOS CON SERVICIO OPORTUNO Y DE VALOR</p>
                <div class="mt-5 text-xl lg:text-3xl flex items-center justify-end space-x-6 text-gray-400">
                  <p class="tracking-normal lg:tracking-[.60em]"><span class="-tracking-[.2em]">-------</span> BC GROUP</p>
                </div>
              </div>
            </div>
          </article>
        </div>
        <label
          for="carousel-3"
          class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-2"
          class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 2-->
        <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="" />
        <div class="carousel-item absolute opacity-0">
          <article class="bg-[url(@/assets/two-businessmen-shaking.jpg)] bg-cover bg-center text-white">
            <div class="bg-black bg-opacity-70 grid grid-cols-12 items-start">
              <div class="border-r border-gray-400 h-full">
                <img class="rotate-[270deg] mt-14" src="@/assets/bc-logo-white.png" alt="Logo Bedoya Consultores" />
              </div>

              <div class="col-span-11 py-44 md:py-72 px-5 md:pr-20 md:pl-44">
                <p class="text-3xl sm:text-5xl lg:text-7xl font-black text-right">SERVICIO PROFESIONALOPORTUNO Y DE VALOR</p>
                <div class="mt-5 text-xl lg:text-3xl flex items-center justify-end space-x-6 text-gray-400">
                  <p class="tracking-normal lg:tracking-[.60em]"><span class="-tracking-[.2em]">-------</span> BC CONSULTING & AUDIT SAS</p>
                </div>
              </div>
            </div>
          </article>
        </div>
        <label
          for="carousel-1"
          class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-3"
          class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 3-->
        <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="" />
        <div class="carousel-item absolute opacity-0">
          <article class="bg-[url(@/assets/teamwork-brainstorming.jpg)] bg-cover bg-center text-white">
            <div class="bg-black bg-opacity-70 grid grid-cols-12 items-start">
              <div class="border-r border-gray-400 h-full">
                <img class="rotate-[270deg] mt-14" src="@/assets/bc-logo-white.png" alt="Logo Bedoya Consultores" />
              </div>

              <div class="col-span-11 py-44 md:py-72 px-5 md:pr-20 md:pl-44">
                <p class="text-3xl sm:text-5xl lg:text-7xl font-black text-right">PROFESIONALES COMPROMETIDOS CON SERVICIO OPORTUNO Y DE VALOR  </p>
                <div class="mt-5 text-xl lg:text-3xl flex items-center justify-end space-x-6 text-gray-400">
                  <p class="tracking-normal lg:tracking-[.60em]"><span class="-tracking-[.2em]">-------</span> BC GROUP</p>
                </div>
              </div>
            </div>
          </article>
        </div>
        <label
          for="carousel-2"
          class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-1"
          class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-dark-blue hover:text-white rounded-full bg-white hover:bg-dark-blue leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!-- Add additional indicators for each slide-->
        <ol class="carousel-indicators">
          <li class="inline-block mr-3">
            <label for="carousel-1" class="carousel-bullet cursor-pointer block text-3xl md:text-8xl text-white hover:text-dark-blue">•</label>
          </li>
          <li class="inline-block mr-3">
            <label for="carousel-2" class="carousel-bullet cursor-pointer block text-3xl md:text-8xl text-white hover:text-dark-blue">•</label>
          </li>
          <li class="inline-block mr-3">
            <label for="carousel-3" class="carousel-bullet cursor-pointer block text-3xl md:text-8xl text-white hover:text-dark-blue">•</label>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
/* .carousel-item {
  -webkit-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
} */
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #012746; /*Set to match the Tailwind colour you want the active one to be */
}
</style>
