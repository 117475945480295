<template>
  <div>
    <div class="car relative shadow-2xl bg-white">
      <div class="car-inner relative overflow-hidden w-full">
        <!--Slide 1-->
        <input class="car-open" type="radio" id="car-1" name="car" aria-hidden="true" hidden="" checked="checked" />
        <div class="car-item absolute opacity-0">
          <section class="text-white bg-black pt-5 space-y-5 h-96">
            <div class="flex justify-center">
              <img class="h-40 object-fill" src="@/assets/logo-Ricardo.png" alt="Imagen logo Ricardo" />
            </div>

            <div>
              <div class="flex justify-center flex-grow">
                <p class="pl-20 lg:pl-56 text-right">&#8213; A L I A D O S</p>
              </div>
              <div class="flex justify-center font-bold text-2xl lg:text-4xl">
                <p class="border-r border-white pr-3">RICARDO</p>
                <p class="pl-3">SABOGAL</p>
              </div>
            </div>
          </section>
        </div>

        <label for="car-2" class="prev ctrl-1 left-1/2 ml-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-right text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <label for="car-5" class="next ctrl-1 right-1/2 mr-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-left text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <!--Slide 2-->
        <input class="car-open" type="radio" id="car-2" name="car" aria-hidden="true" hidden="" />
        <div class="car-item absolute opacity-0">
          <section class="text-white bg-black pt-14 sm:pt-7 space-y-16 h-96">
            <div class="flex justify-center">
              <img class="h-14 md:h-20 object-fill" src="@/assets/logo-nieto-osorio-y-asociados.png" alt="Imagen logo nieto osorio y asociados" />
            </div>

            <div>
              <div class="flex justify-center flex-grow">
                <p class="pl-20 lg:pl-56 text-right">&#8213; A L I A D O S</p>
              </div>
              <div class="flex justify-center font-bold text-2xl lg:text-4xl">
                <p class="border-r border-white pr-3">NIETO </p>
                <p class="pl-3">OSORIO</p>
              </div>
            </div>
          </section>
        </div>

        <label for="car-3" class="prev ctrl-2 left-1/2 ml-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-right text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <label for="car-1" class="next ctrl-2 right-1/2 mr-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-left text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <!--Slide 3-->
        <input class="car-open" type="radio" id="car-3" name="car" aria-hidden="true" hidden="" />
        <div class="car-item absolute opacity-0">
          <section class="text-white bg-black pt-5 space-y-5 h-96">
            <div class="flex justify-center">
              <img class="h-40 object-fill" src="@/assets/logo-ussa.png" alt="Imagen logo Ussa" />
            </div>

            <div>
              <div class="flex justify-center flex-grow">
                <p class="pl-20 lg:pl-56 ju text-right">&#8213; A L I A D O S</p>
              </div>
              <div class="flex justify-center font-bold text-2xl lg:text-4xl">
                <p class="border-r border-white pr-3">C&A</p>
                <p class="pl-3">USSA</p>
              </div>
            </div>
          </section>
        </div>

        <label for="car-4" class="prev ctrl-3 left-1/2 ml-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-right text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <label for="car-2" class="next ctrl-3 right-1/2 mr-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-left text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <!--Slide 4-->
        <input class="car-open" type="radio" id="car-4" name="car" aria-hidden="true" hidden="" />
        <div class="car-item absolute opacity-0">
          <section class="text-white bg-black pt-5 space-y-5 h-96">
            <div class="flex justify-center">
              <img class="h-20 object-fill" src="@/assets/logo-strategas.jpeg" alt="Imagen logo Ussa" />
            </div>

            <div>
              <div class="flex justify-center flex-grow">
                <p class="pl-20 lg:pl-56 ju text-right">&#8213; A L I A D O S</p>
              </div>
              <div class="flex justify-center font-bold text-2xl lg:text-4xl">
                <p class="border-r border-white pr-3">STRATEGAS</p>
                <p class="pl-3">INVERSIÓN</p>
              </div>
            </div>
          </section>
        </div>

        <label for="car-5" class="prev ctrl-4 left-1/2 ml-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-right text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <label for="car-3" class="next ctrl-4 right-1/2 mr-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-left text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <!--Slide 5-->
        <input class="car-open" type="radio" id="car-5" name="car" aria-hidden="true" hidden="" />
        <div class="car-item absolute opacity-0">
          <section class="text-white bg-black pt-5 space-y-5 h-96">
            <div class="flex justify-center">
              <img class="h-28 object-fill" src="@/assets/logo-registra-white.png" alt="Imagen logo Registra" />
            </div>

            <div>
              <div class="flex justify-center flex-grow">
                <p class="pl-20 lg:pl-56 ju text-right">&#8213; A L I A D O S</p>
              </div>
              <div class="flex justify-center font-bold text-2xl lg:text-4xl">
                <p class="border-r border-white pr-3">REGISTRA</p>
                <p class="pl-3">DATOS</p>
              </div>
            </div>
          </section>
        </div>

        <label for="car-1" class="prev ctrl-5 left-1/2 ml-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-right text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>

        <label for="car-4" class="next ctrl-5 right-1/2 mr-5 absolute cursor-pointer hidden z-10 bottom-10 my-auto">
          <i class="fa-solid fa-arrow-left text-6xl text-gray-400 hover:text-dark-blue"></i>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.car-open:checked + .car-item {
  position: static;
  opacity: 100;
}
/* .car-item {
  -webkit-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
} */
#car-1:checked ~ .ctrl-1,
#car-2:checked ~ .ctrl-2,
#car-3:checked ~ .ctrl-3,
#car-4:checked ~ .ctrl-4,
#car-5:checked ~ .ctrl-5 {
  display: block;
}

</style>
