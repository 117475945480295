<template>
  <router-view />
  <FooterPage/>
</template>

<style>
#app {
  font-family: "Fira Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import FooterPage from "@/components/FooterPage.vue";

export default {
  name: "App",
  components: {
    FooterPage
  },
};
</script>
