<template>
  <div class="relative">
    <nav class="absolute top-0 left-0 right-0 bg-transparent">
      <div class="flex lg:hidden justify-end pt-8 pb-3 pr-4">
        <button id="burguer-button" @click="myFunctionClick()" class="flex items-center px-3 py-2 shadow-lg border-2 rounded text-white border-white active:text-dark-blue active:border-dark-blue">
          <svg class="fill-current h-5 w-6" viewBox="0 0 20 20">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div id="menu-list" class="w-full hidden lg:flex lg:w-auto bg-black lg:bg-transparent py-5 lg:py-0 border-2 border-white lg:border-0 rounded-xl">
        <ul class="text-md flex-col items-center space-y-2 lg:space-y-0 lg:flex-grow lg:flex-row flex space-x-0 lg:space-x-10 mt-5 lg:mt-0 justify-end">
          <li class="w-40 xl:w-48">
            <router-link to="/" class="py-2 flex justify-end">Home</router-link>
          </li>

          <li class="w-40 xl:w-48">
            <router-link to="/Nosotros" class="py-2 flex justify-end">Nosotros</router-link>
          </li>

          <li class="w-40 xl:w-48">
            <router-link to="/Servicios" class="py-2 flex justify-end">Servicios</router-link>
          </li>

          <li class="w-40 xl:w-48">
            <router-link to="/Experiencia" class="py-2 flex justify-end">Experiencia</router-link>
          </li>

          <li class="w-40 xl:w-48">
            <router-link to="/Contacto" class="py-2 flex justify-end">Contacto</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {
    myFunctionClick: function () {
      const menu = document.querySelector("#menu-list");
      if (menu) {
        menu.classList.toggle("hidden");
      }
    },
  },
};
</script>

<style scoped>
nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: white;
  border-top: 5px solid white;
}

nav a.router-link-exact-active {
  border-top: 5px solid #012746;
  
}
</style>
